import './App.css';
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route,Redirect ,Link} from 'react-router-dom'
import Dcma from './page/Dcma'
import Home from './Home'
import Mine from './page/Mine'
import Contactus from './page/Contactus'
function Example() {
  const [count, setCount] = useState(0);
  useEffect(() => {
    document.title = `You clicked ${count} times`;
  });

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}
class RouterTest extends React.Component {
  render() {
    return (
      <div>
      <Link to="/writer">写作</Link>
      <Link to="/home">首页</Link>
      <Link to="/dcma">隐私</Link>
      </div>
    )
  }
}
function App(props) {
  return (
    <Router>
      <div className="App">
        <Route  exact path="/" render={()=><Redirect to='/home' />}/>
        <Route  path='/dcma' component={Dcma} />
        <Route  path='/home' component={Home} />
        <Route  path='/writer' component={Mine} />
        <Route  path='/contactus' component={Contactus} />
        {/* <Home /> */}
        {/* <RouterTest/> */}
      </div>
   </Router>
  );
}
export default App;
