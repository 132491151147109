import React from "react";
import { BrowserRouter as Router,Route } from "react-router-dom";
import { API } from "../utils/api";
import { gostoryLog } from "../utils/log";
import './index.scss'
import News from "../page/News";
import Search from "../page/Search";
import logo from '../assets/logo.png'
import pic1 from '../assets/pic_1.png'
import pic2 from '../assets/pic_2.png'
import logoicon from '../assets/logoicon.png'
import gsicon from '../assets/gsicon.png'
import heart from '../assets/heart.png'
import list1 from '../assets/list_1.png'
import list2 from '../assets/list_2.png'
import list3 from '../assets/list_3.png'
import m_list1 from '../assets/m_list_1.png'
import m_list2 from '../assets/m_list_2.png'
import m_list3 from '../assets/m_list_3.png'
import freestyle from '../assets/free_style.png'
import m_freestyle from '../assets/m_free_style.png'
import freebg from '../assets/free_bg.png'
import m_freebg from '../assets/m_story.png'
import author from '../assets/author.png'
import boticon from '../assets/bot_icon.png'
import android from '../assets/android.png'
import iphone from '../assets/iphone.png'
import m_author from '../assets/m_author.png'
import Footer from "../components/Footer";
// import author_bg from '../assets/author_bg.png'

var u = navigator.userAgent;
var is_ios = (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) || (!!u.match(/iPhone|mac|iPod|iPad|ios/i));
var is_android = (!!u.match(/AppleWebKit.*Mobile.*/)) && (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1);
var iosAndroid = '';
if (is_ios) {
  iosAndroid = 'ios';
} else {
  is_android ? iosAndroid = 'android' : iosAndroid = 'pc';
}
console.log(iosAndroid, 'iosAndroid');
const navs = [
    {id:1,img:list1,imgm:m_list1,title:'Baking Rythmn',contain:'You have your entire life mapped out, but you don’t expect to find a deep attraction to the cocky bad boy Kelan Turner, who can get any girls he wants, except you.',list:[{id:121,text:"Bad Boy"},{id:122,text:"Romance"},{id:123,text:"High School"}]},
    {id:2,img:list2,imgm:m_list2,title:'Forever and Always',contain:'Being beaten frequently, your wolf wakes up. It’s time for you to take revenge! But you meet your mate suddenly. Will your fate turn over?',list:[{id:221,text:"Werewolf"},{id:222,text:"Steamy Romance"}]},
    {id:3,img:list3,imgm:m_list3,title:'Prisoner of Love',contain:"As you try to break away from a contract marriage, the truth of this plot is unveiled and the entanglement with your husband gets rapidly complicated...",list:[{id:321,text:"Romance"},{id:322,text:"Contract Marriage"}]},
]
const authorlist=[
  {id:101,text:'Love Romantic Story'},
  {id:102,text:'Have an experience in interactive story writing'},
  {id:103,text:'Game or novel writer'},
  {id:104,text:'Amateurs welcome!'},
]
export default class Home extends React.Component {
    state = {
      iosAndroid:'',
      u:u
    }
    componentDidMount(){
        document.title = "Go Story";
        this.getApi();
    }
    async getApi(){
      gostoryLog(iosAndroid,'logPv','home_Logpv','1',u)
    //   await API.post('https://log.webfic.com/h5_stand_final_log.php',{
    //     id:3
    //   })
    }
    renderNavs = () => {
        return navs.map(item =>
            <div className='item' key={item.id}>
                <div className='list_1'> <img src={item.img} alt=''/> </div>
                <div className='list_2'> <img src={item.imgm} alt=''/> </div>
                <div className='bottom'>
                    <h3>{item.title}</h3>
                    <div className='mainlist'>
                    <div className='mainlistflex'>
                    {item.list.map(list=>{
                        return <div className='itemborder' key={list.id}>
                            <span className='itemlist'>{list.text}</span>
                        </div>
                    })}
                    </div>
                    </div>
                    <p>
                        {item.contain}
                    </p>
                </div>
            </div>
        )
    }
    renderAuthor = () => {
        return authorlist.map(item =>
            <div className='author_list' key={item.id}>
                <div className='flex_icon'><span className='icon'></span></div><p>{item.text}</p>
            </div>
        )
    }
    render() {
        return (
            <div className="home">
                <div className="banner">
                    <div className='banner_title'></div>
                  <div className='main'> 
                     <div className='top'>
                      <span className='left_logo'> <img src={gsicon} alt=''/></span>
                      <div className='contact_us' onClick = {()=>{
                        console.log('ss');
                        gostoryLog(iosAndroid,'logClick','contactus_logClick','2',u)
                        this.props.history.push('/contactus')
                        window.scrollTo(0, 0)
                      }}>Contact Us</div>
                     </div>
                     <div className='pc_top'>
                     <span className='left_logo'> <img src={logoicon} alt='' /></span>
                     <span className='right_logo'> <img src={logo} alt='' /></span>
                      <div className='contact_us' onClick = {()=>{
                        console.log('ss');
                        gostoryLog(iosAndroid,'logClick','contactus_logClick','2',u)
                        this.props.history.push('/contactus')
                        window.scrollTo(0, 0)
                      }}>Contact Us</div>
                     </div>
                     <div className='banner_flex'>
                     <div className='introduce'>
                      <h1>What is GoStory</h1>
                      <p>Gostory is an interactive stories application in which you can design and choose your own story. Recommended for everyone who loves reading and playing games at the same time!</p>
                      <div className="download">
                          <span className="download_ios" onClick={()=>{
                            gostoryLog(iosAndroid,'logClick','download_ios_logClick','2',u)
                            window.location.href="https://apps.apple.com/cn/app/id1610053050" //jump ios
                            }}><img src={iphone} alt=''/>Get it on iOS</span>
                          <span className="download_android" onClick={()=>{
                            gostoryLog(iosAndroid,'logClick','download_android_logClick','2',u)
                            window.location.href="https://play.google.com/store/apps/details?id=com.storymatrix.gostory" //jump android
                            }}><img src={android} alt=''/>Get it on Android</span>
                      </div>
                      <div className="m_download">
                          <span className="download_android" onClick={()=>{
                            gostoryLog(iosAndroid,'logClick','download_android_logClick','2',u)
                            window.location.href="https://play.google.com/store/apps/details?id=com.storymatrix.gostory" //jump android
                            }}><img src={android} alt=''/>Get it on Android</span>
                          <span className="download_ios" onClick={()=>{
                            gostoryLog(iosAndroid,'logClick','download_ios_logClick','2',u)
                            window.location.href="https://apps.apple.com/cn/app/id1610053050" //jump ios
                            }}><img src={iphone} alt=''/>Download on iOS</span>
                      </div>
                     </div>
                     <div className='pic_1_div'> <img src={pic1} alt=''/> </div>
                     </div>
                  </div>    
                </div> 
                <div className="contain">
                  <div className='main'> 
                     <div className='heart'>
                         <img src={heart} alt='' />
                     </div>
                     <div className='introduce'>
                      <h1>Product Overview</h1>
                        <div className='introduce_pc'>
                           <p>- Fascinating stories waiting for you to unlock!</p>
                           <p>- Dress your avatar as you wish.</p>
                           <p>- Fascinating stories waiting for you to unlock!</p>
                        </div>
                      <div className='introduce_m'>
                        <p>Fascinating stories waiting for you to unlock! Dress your avatar as you wish.<br/> Empowering you to define the destiny of yourself.</p>
                      </div>
                     </div>
                    <div className='pic_2_div'> <img src={pic2} alt=''/> </div>
                  </div>    
                </div> 
                <div className="list">
                  <div className='main'> 
                  <h1>Trending Stories</h1>
                    <div className='top'>
                        {this.renderNavs()}
                    </div>
                  </div>    
                </div> 
                <div className="story">
                  <div className='main'> 
                    <div className='pic_2_div'> <img src={freestyle} alt='' /> </div>
                    <div className='m_pic_2_div'> <img src={m_freestyle} alt='' /> </div>
                    <div className='top'>
                        <h2>Our Fascinating Genres</h2>
                        <p>Bad Boy, Romance, Steamy Romance, High School, Werewolf, and many more coming out soon!</p>
                    </div>
                    <div className='freebg'> <img src={freebg} alt='' /> </div>
                    <div className='m_freebg'> <img src={m_freebg} alt='' /> </div>
                  </div>    
                </div> 
                <div className="author">
                  <div className='main'> 
                    <div className='aubg'></div>
                    <div className='partin'>
                        <p className='title'>Careers:</p>
                        <h2>We are Hiring!    Part-time Writer</h2>
                        <p className='title'>Recruitment：</p>
                        {this.renderAuthor()}
                        <p className='title'>For more details, send your CV to:</p>
                        <p><a href="mailto:content@gostory.com">content@gostory.com</a></p>
                        <div className='partbtn' onClick={()=>{
                            gostoryLog(iosAndroid,'logClick','author_partinbtn_logClick','2',u)
                            this.props.history.push('/writer')
                            window.scrollTo(0, 0)
                          }}>Apply Now !</div>
                    </div>
                    <div className='pic_2_div'> <img src={author} alt='' /> </div>
                    <div className='m_pic_2_div'> <img src={m_author} alt='' /> </div>
                  </div>    
                </div> 
                <Footer/>
                {/* <div className="fotter">
                  <div className='main'> 
                    <div className='bot_title'>
                       <img src={boticon} alt='' />
                    </div>
                    <div className='bot_content'>
                      <p className='bot_des'>Gostory is an interactive stories application in which you can design and choose your own story. Recommended for everyone who loves reading and playing games at the same time!</p>
                      <div className='bot_email'>
                          <p>Contact Us</p>
                          <p>support@gostory.com</p>
                      </div>
                      <p className="bot_terms">
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/terms_of_use_gostory.html"}} >Terms of Service</span> | 
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/privacy_policy_gostory.html"}} > Privacy Policy</span> | 
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/dcma_gostory.html"}} > DCMA</span></p>
                    </div>
                    <p className='bot_bottom'>
                       Copyright © 2022 GoStory, All Right Reserved STORYMATRIX PTE. LTD.
                    </p>
                  </div>    
                </div> 
                <div className="m_fotter">
                  <div className='main'> 
                    <div className='bot_title'>
                       <img src={boticon} alt='' />
                    </div>
                    <div className='bot_content'>
                      <div className='bot_email'>
                      </div>
                      <p className="bot_terms">
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/terms_of_use_gostory.html"}} >Terms of Service</span> | 
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/privacy_policy_gostory.html"}} > Privacy Policy</span> | 
                          <span  onClick={()=>{window.location.href="https://privacy.gostory.com/privacy/gostory/dcma_gostory.html"}} > DCMA</span></p>
                    </div>
                    <p className='bot_bottom'>
                    Copyright © 2022 GoStory, All Right Reserved 
                    </p>
                  </div>    
                </div>  */}
                <Route path="/home/news" component={News}></Route>
                <Route path="/home/search" component={Search}></Route>
            </div>
        )
    }
}