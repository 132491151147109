import react from "react";
import boticon from '../../assets/bot_icon.png'
import './index.scss'
export default class Footer extends react.Component{
    render() {
        return <div>
            <div className="fotter">
                <div className='main'>
                    <div className='bot_title'>
                        <img src={boticon} alt='' />
                    </div>
                    <div className='bot_content'>
                        <p className='bot_des'>Gostory is an interactive stories application in which you can design and choose your own story. Recommended for everyone who loves reading and playing games at the same time!</p>
                        <div className='bot_email'>
                            {/* <p>Contact Us</p>
                            <p>support@gostory.com</p> */}
                        </div>
                        <p className="bot_terms">
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/terms_of_use_gostory.html" }} >Terms of Service</span> |
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/privacy_policy_gostory.html" }} > Privacy Policy</span> |
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/dcma_gostory.html" }} > DCMA</span></p>
                    </div>
                    <p className='bot_bottom'>
                        Copyright © 2022 GoStory, All Right Reserved STORYMATRIX PTE. LTD.
                    </p>
                </div>
            </div>
            <div className="m_fotter">
                <div className='main'>
                    <div className='bot_title'>
                        <img src={boticon} alt='' />
                    </div>
                    <div className='bot_content'>
                        <div className='bot_email'>
                            {/* <span>Contact Us : </span><span>support@gostory.com</span> */}
                        </div>
                        <p className="bot_terms">
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/terms_of_use_gostory.html" }} >Terms of Service</span> |
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/privacy_policy_gostory.html" }} > Privacy Policy</span> |
                            <span onClick={() => { window.location.href = "https://privacy.gostory.com/privacy/gostory/dcma_gostory.html" }} > DCMA</span></p>
                    </div>
                    <p className='bot_bottom'>
                        Copyright © 2022 GoStory, All Right Reserved
                    </p>
                </div>
            </div>
        </div>
    }
}