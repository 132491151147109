import React from "react";
import { gostoryLog } from "../../utils/log";
import './index.scss'
import contactusimg from '../../assets/contactusimg.png'
import contactus from '../../assets/contactus.png'
import Footer from "../../components/Footer";
import Header from "../../components/Header";

var u = navigator.userAgent;
var is_ios = (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) || (!!u.match(/iPhone|mac|iPod|iPad|ios/i));
var is_android = (!!u.match(/AppleWebKit.*Mobile.*/)) && (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1);
var iosAndroid = '';
if (is_ios) {
  iosAndroid = 'ios';
} else {
  is_android ? iosAndroid = 'android' : iosAndroid = 'pc';
}
var pageFrom = 'contactus_gohomebtn'
export default class Contactus extends React.Component {
     goHome = ()=>{
       gostoryLog(iosAndroid,'logClick','contactus_gohomebtn_logClick','2',u)
       this.props.history.push('/home')
       window.scrollTo(0, 0)
     }
     render(){
         return <div className="contactus">
          <Header pageName={pageFrom} />
         <div className='content1'>
             <div className='main'>
                 <img src={contactusimg} alt=''/>
                 <h2>Contact Us</h2>
                 <p className='title'>Dear user,</p>
                 <p className='p_normal'>Thank you for your interest in GoStory. <br/>We want to hear your voice.</p>
                 <p className='p_normal'>If you are looking for business opportunities,<br/> please send us email to <span className='special_color_title'><a href="mailto:support@gostory.com">support@gostory.com</a></span></p>
                 <p className='p_normal'>If you are interested in any work opportunities here with us GoStory Team,<br/> please contact us with: <span className='special_color_title'><a href="mailto:content@gostory.com">content@gostory.com</a></span></p>
                 <p className='p_normal'>If you have other questions or concerns,<br/> please send us email to <span className='special_color_title'><a href="mailto:support@gostory.com">support@gostory.com</a></span></p>
                 <p className='p_normal'>Thank you again.<br/>Best regards,</p>
                 <p className='title gs_title'>GoStory</p>
             </div>
         </div>
         <div className='m_content'>
             <div className='main'>
                 <div className="first_page">
                 <h2>Contact Us</h2>
                 <p className='title special_title'>Dear user,</p>
                 <p className='normal'>Thank you for your interest in GoStory. We want to hear your voice.</p>
                 <p className='normal'>If you are looking for business opportunities, please send us email to <span className='re_title'><a href="mailto:support@gostory.com">support@gostory.com</a></span></p>
                 <p className='normal'>If you are interested in any work opportunities here with us GoStory Team, please contact us with: <span className='re_title'><a href="mailto:content@gostory.com">content@gostory.com</a></span></p>
                 <p className='normal'>If you have other questions or concerns, please send us email to <span className='re_title'><a href="mailto:support@gostory.com">support@gostory.com</a></span></p>
                 <p className='normal'>Thank you again.<br/>Best regards,</p>
                 <p className='go_normal'>GoStory</p>
                 <img src={contactus} alt=''/>
                 </div>
             </div>
         </div>
         <Footer/> 
     </div>
     }
}