import axios from "axios";

const API = axios.create({
    baseURL:'/',
    transformRequest:[function (data,headers) {
        // data = JSON.stringify(data)
        console.log('处理数据',data);
        return data
    }],
    transformResponse: [function (data) {
        return data;
    }], 
    headers:{
        "Content-Type": "multipart/form-data;charset=UTF-8",
        "Accept": "*/*",
        // "dataType": "jsonp",
        // "Content-Type": "application/x-www-form-json;charset=UTF-8"
    },
    timeout:5000,
    responseType:'json',
    responseEncoding:'utf8',
    maxContentLength:20000,
    maxRedirects:5,
})
// 添加请求拦截器
API.interceptors.request.use(config=>{
    console.log('qian',config);
    return config;
  },error=>{
    return Promise.reject(error);
  });

// 添加响应拦截器
API.interceptors.response.use(response=>{
    console.log('hou');
    return response;
  },error=>{
    return Promise.reject(error);
  });
export { API }