import {API} from './api'
export const gostoryLog = async (iosAndroid,type,event,action,ua)=>{
    console.log('change,start log');
    try {
        var logData = {
            log_id: randomString(),// 随机生成，16位字符串即可
            bline: 'gs',
            pline: iosAndroid,
            cts: new Date().getTime(),
            pkna: '',
            app_version: '5.1.0',
            chid: '',
            uid: '',
            imei: '',
            oaid: '',
            idfa: '',
            type: type,
            event: event,
            data: {
                action: action,
                ua: ua,
                from:'pcAndm'
            }
        }
        await API({
            method: 'get',
            url: 'https://log.gostory.com/h5_standard_final_log.php',
            params: 
				{json:JSON.stringify(logData)}
			,
            success: function () {
                console.log('success')
            }
        })
    } catch (error) {
        console.log('error');
    }
}
function randomString(len) {
    len = len || 16;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}