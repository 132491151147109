import { gostoryLog } from "../../utils/log";
import { withRouter } from "react-router-dom";
import logo from '../../assets/logo.png'
import logoicon from '../../assets/logoicon.png'
import gsicon from '../../assets/gsicon.png'
import './index.scss'

var u = navigator.userAgent;
var is_ios = (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) || (!!u.match(/iPhone|mac|iPod|iPad|ios/i));
var is_android = (!!u.match(/AppleWebKit.*Mobile.*/)) && (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1);
var iosAndroid = '';
if (is_ios) {
  iosAndroid = 'ios';
} else {
  is_android ? iosAndroid = 'android' : iosAndroid = 'pc';
}

function Header(props){
    const goHome = ()=>{
        gostoryLog(iosAndroid,'logClick', props.pageName+'_logClick','2',u)
        props.history.push('/home')
        window.scrollTo(0, 0)
    }
    return <div className="contact">
        <div className="pc_banner1">
            <div className='banner_title'></div>
            <div className='main'>
                <div className='top'>
                    <span className='left_logo'> <img src={logoicon} alt='' /></span>
                    <span className='right_logo'> <img src={logo} alt='' /></span>
                </div>
                <div className="back" onClick={goHome}>Back to Home</div>
            </div>
        </div>
        <div className="banner1">
            <div className='banner_title'></div>
            <div className='main'>
                <div className='top'>
                    <span className='left_logo'> <img src={gsicon} alt='' /></span>
                </div>
                <div className="back" onClick={goHome}>Back to Home</div>
            </div>
        </div>
    </div>
    
}
export default withRouter(Header)