import React from "react";
import { gostoryLog } from "../../utils/log";
import './index.scss'
import author_img from '../../assets/author_img.png'
import Header from "../../components/Header";
import Footer from "../../components/Footer";

var u = navigator.userAgent;
var is_ios = (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) || (!!u.match(/iPhone|mac|iPod|iPad|ios/i));
var is_android = (!!u.match(/AppleWebKit.*Mobile.*/)) && (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1);
var iosAndroid = '';
if (is_ios) {
  iosAndroid = 'ios';
} else {
  is_android ? iosAndroid = 'android' : iosAndroid = 'pc';
}
var pageFrom = 'author_gohomebtn'
export default class Mine extends React.Component {
     goHome = ()=>{
       gostoryLog(iosAndroid,'logClick','write_gohomebtn_logClick','2',u)
       this.props.history.push('/home')
       window.scrollTo(0, 0)
     }
     render(){
         return <div className="mine">
          <Header pageName={pageFrom} />
         <div className='content1'>
             <div className='main'>
                 <img src={author_img} alt=''/>
                 <h2>Part-time Writer Recruitment</h2>
                 <p className='title'>Job requirements:</p>
                 <p className='normal'>Write interactive novel game script!</p>
                 <p className='p_title'>Stylistic</p>
                 <p className='p_normal'>Romance stories with wide range of elements or genres embedded.</p>
                 <p className='p_title'>Style</p>
                 <p className='p_normal'>Dramatic, Engaging, Fascinating, Intense, Fast-paced, and Eye-catching from the beginning.</p>
                 <p className='p_title'>Word count</p>
                 <p className='p_normal'>2.5k-3k words/chapter, 10-15 chapters/story, 25k-45k words/story.</p>
                 <p className='p_title'>Text format</p>
                 <p className='p_normal'>Dialogue-heavy, supplemented with narrations and monologues in screenplay format.</p>
                 <p className='title'>What we can provide:</p>
                 <p className='first_normal'>1.We want our writers to benefit directly from their own work. Writers can always get a 50:50 profit share of your interactive story on our platform. (For more cooperative modes, contact <span className='email_style'><a href="mailto:content@gostory.com">content@gostory.com</a></span></p>
                 <p className='normal'>2.Writer-friendly writing portal for your interactive story writing.</p>
                 <p className='normal'>3.It’s a work from home part-time job.</p>
                 <p className='normal'>4.Professional editing, art-designing and producing for your story, with commercial release and promotion. Your story will be displayed as engaging interactive story in game.</p>
                 <p className='title'>Workflow:</p>
                 <p className='normal'>1.Send your CV and writing prompt test to <span className='email_style'><a href="mailto:content@gostory.com">content@gostory.com</a></span> </p>
                 <p className='first_normal'>2.Pass the writing prompt test.</p>
                 <p className='normal'>3.Decide with GoStory on the idea of your story and fill in the <span className='span_title'>‘Outline Template’</span>   form with essential statements of how to develop the story chapter by chapter to show the story flow and the chemistry between leading characters.</p>
                 <p className='sec_normal'>4.Chapter-by-chapter outline gets approved.</p>
                 <p className='sec_normal'>5.Sign the contract.</p>
                 <p className='normal'>6.Start writing.</p>
                 <p className='sec_normal'>7.Earn money. Yeah!</p>
                 <p className='title'>Take a test?</p>
                 <p className='sec_normal'>Plz read the guidelines before getting started and submit your NAMED work to<br/><span className='email_style'><a href="mailto:content@gostory.com">content@gostory.com</a></span> within one week.</p>
                 <p className='p_weight_title'>You are a young and beautiful associate professor. New semester begins, a hot but aloof freshman comes to your life. He tries his hardest to create chances to get close to you since you two come into each other from time to time. Will you accept his love and fall over for the dangerously attractive boy while you may lose your career?</p>
                 <p className='normal'>Write the opening chapter of a multi-chapters story, or tell a complete short story. All information provided here are inspirations for your creative writing, not a writing Bible to follow. Please feel free to change any settings or details. Please develop branches writing (including payment option designs). Word count: 1500-2000 words.</p>
                 <p className='normal lastpstyle'>Please do your best to show your abilities in story logic and pacing, creating distinctive characters, varied and accurate language, and tempting paid options.</p>
             </div>
         </div>
         <div className='m_content'>
             <div className='main'>
                 <img src={author_img} alt=''/>
                 <div className="first_page">
                 <h2>Part-time Writer Recruitment</h2>
                 <p className='title titlespecial'>Job requirements:</p>
                 <p className='normal'>Write interactive novel game script!</p>
                 <p className='p_title'>Stylistic</p>
                 <p className='p_normal'>Romance stories with wide range of elements or genres embedded.</p>
                 <p className='p_title'>Style</p>
                 <p className='p_normal'>Dramatic, Engaging, Fascinating, Intense, Fast-paced, and Eye-catching from the beginning.</p>
                 <p className='p_title'>Word count</p>
                 <p className='p_normal'>2.5k-3k words/chapter, 10-15 chapters/story, 25k-45k words/story.</p>
                 <p className='p_title'>Text format</p>
                 <p className='p_normal'>Dialogue-heavy, supplemented with narrations and monologues in screenplay format.</p>
                 </div>
                 <div className="sec_page">
                 <p className='title'>What we can provide:</p>
                 <p className='first_normal'>1.We want our writers to benefit directly from their own work. Writers can always get a 50:50 profit share of your interactive story on our platform. (For more cooperative modes, contact <span className='re_title'><a href="mailto:content@gostory.com ">content@gostory.com</a></span> </p>
                 <p className='normal'>2.Writer-friendly writing portal for your interactive story writing.</p>
                 <p className='normal'>3.It’s a work from home part-time job.</p>
                 <p className='normal'>4.Professional editing, art-designing and producing for your story, with commercial release and promotion. Your story will be displayed as engaging interactive story in game.</p>
                 </div>
                 <div className="third_page">
                 <p className='title'>Workflow:</p>
                 <p className='normal'>1.Send your CV and writing prompt test to <span className='re_title'><a href="mailto:content@gostory.com">content@gostory.com</a></span></p>
                 <p className='first_normal'>2.Pass the writing prompt test.</p>
                 <p className='normal'>3.Decide with GoStory on the idea of your story and fill in the <span className='span_title'>‘Outline Template’</span> form with essential statements of how to develop the story chapter by chapter to show the story flow and the chemistry between leading characters.</p>
                 <p className='sec_normal'>4.Chapter-by-chapter outline gets approved.</p>
                 <p className='sec_normal'>5.Sign the contract.</p>
                 <p className='normal'>6.Start writing.</p>
                 <p className='normal'>7.Earn money. Yeah!</p>
                 </div>
                 <div className="forth_page">
                 <p className='title'>Take a test?</p>
                 <p className='sec_normal'>Please read the guidelines before getting started and submit your NAMED work to <span className='re_title'><a href="mailto:content@gostory.com">content@gostory.com</a></span> within one week.</p>
                 <p className='p_weight_title'>You are a young and beautiful associate professor. New semester begins, a hot but aloof freshman comes to your life. He tries his hardest to create chances to get close to you since you two come into each other from time to time. Will you accept his love and fall over for the dangerously attractive boy while you may lose your career?</p>
                 <p className='normal'>Write the opening chapter of a multi-chapters story, or tell a complete short story. All information provided here are inspirations for your creative writing, not a writing Bible to follow. Please feel free to change any settings or details. Please develop branches writing (including payment option designs). Word count: 1500-2000 words.</p>
                 <p className='normal'>Please do your best to show your abilities in story logic and pacing, creating distinctive characters, varied and accurate language, and tempting paid options.</p>
                 </div>
             </div>
         </div>
         <Footer/>
     </div>
     }
}